import React, { useState } from 'react';
import { Tabs, Form, Input, message, Button, Checkbox, Alert } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStateContext } from "../../contexts/ContextProvider";
import logo from "../../data/Metiolis.png";
import background from "../../data/statie-epurare-3.jpg"
const { TabPane } = Tabs;

const Login = () => {
    const [formLogin] = Form.useForm();
    const [formRegister] = Form.useForm();
    const [email, setEmail] = useState("");
    const [activeTab, setActiveTab] = useState("login");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const {setToken, setUserRole} = useStateContext();
    const [isLogin, setIsLogin] = useState(true); // True pentru login, false pentru înregistrare
    const [phone, setPhone] = useState("");
    const [registrationEmail, setRegistrationEmail] = useState("");
    

    const navigate = useNavigate();
    const location = useLocation();
    const from = ((location.state)?.from.pathname) || '/';

    const submitRegistration = () => {
        console.log("Datele de înregistrare:", { phone, registrationEmail });
        alert("Datele au fost expediate. Veți fi contactat de unul dintre reprezentanții noștri.");
        // Resetare formular de înregistrare
        setPhone("");
        setRegistrationEmail("");
    };

    const handleLogin = async (values) => {
        const { email, password } = values; // Destructurează valorile email și parolă din formular
    
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, requestOptions);
            const data = await response.json();
    
            if (!response.ok) {
                throw new Error(data.detail || "An error occurred during login.");
            }
    
            setToken(data.token); // Salvează token-ul primit în context
            setUserRole(data.role); // Salvează rolul utilizatorului în context
            navigate(from, { replace: true }); // Navighează la pagina de origine sau la pagina de start
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    const handleRegister = (values) => {
        // Logica de înregistrare
        console.log("Datele de înregistrare:", values);
        message.success("Multumim va vom contacta in curand")
    };


    return (

        <div className="min-h-screen flex justify-center items-center bg-gray-50" style={{backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundBlendMode: 'overlay', backgroundColor: 'rgba(255,255,255,0.93)'}}>

            <div className="max-w-md w-full space-y-8">
            <div className='flex justify-center text-xl'>
            <img src={logo} alt="Metiolis Logo" className="h-12 md:h-16" />
            </div>
                <Tabs defaultActiveKey="login" centered onChange={setActiveTab}>
                    <TabPane tab="Login" key="login">
                        <Form form={formLogin} layout="vertical" onFinish={handleLogin}>
                            <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Te rog introdu adresa de email!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="password" label="Parolă" rules={[{ required: true, message: 'Te rog introdu parola!' }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="w-full bg-blue-500 text-white">
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab="Înregistrare" key="register">
                        <Form form={formRegister} layout="vertical" onFinish={handleRegister}>
                            <Form.Item name="registrationEmail" label="Email" rules={[{ required: true, message: 'Te rog introdu adresa de email pentru înregistrare!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="phone" label="Telefon" rules={[{ required: true, message: 'Te rog introdu numărul de telefon!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="w-full bg-green-500 text-white">
                                    Înregistrează-te
                                </Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                </Tabs>
            </div>
        </div>


    );
};

export default Login;

