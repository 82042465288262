import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-2); // Navigate back
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100 text-gray-700">
      <div className="text-center">
        <div className="text-6xl font-semibold">404</div>
        <div className="text-xl mb-4">Pagina nu a fost găsită!</div>
        <p className="mb-8">Ne pare rău, pagina pe care încercați să o accesați nu există sau a fost ștearsă.</p>
        <Button
          onClick={handleGoBack}
          type="primary"
          icon={<RollbackOutlined />}
          className="mt-4"
        >
          Înapoi la pagina anterioară
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
