import React, { Suspense } from 'react'
import RequireUser from './contexts/RequireUser'; 
import FullScreenLoader from "./components/smallcomp/FullScreeenLoader"
import { registerLicense } from '@syncfusion/ej2-base';


import { Route, Routes } from 'react-router-dom';
import Login from "./pages/Dashboard/Login"
import NotFoundPage from "./components/NotFoundPage"

const Dashboard = React.lazy(() => import('./pages/Dashboard/Dashboard'));
const Common = React.lazy(() => import('./components/Common'));
const Indicator = React.lazy(() => import('./pages/Dashboard/Indicator'));
const City = React.lazy(() => import('./pages/Dashboard/City'));
const ProjectPage = React.lazy(() => import('./pages/Dashboard/ProjectPage'));
const Settings = React.lazy(() => import('./pages/Dashboard/Settings.jsx'));
const Robots = React.lazy(() => import('./pages/Dashboard/Robots/Robots.jsx'));
const RobotsMain = React.lazy(() => import('./pages/Dashboard/Robots/RobotsMain.jsx'));
const AllCases = React.lazy(() => import('./pages/Dashboard/Case/AllCases.jsx'));
const CasePage = React.lazy(() => import('./pages/Dashboard/Case/CasePage.jsx'));
const Cameras = React.lazy(() => import('./pages/Dashboard/Cameras/Cameras.jsx'));
const Player = React.lazy(() => import('./pages/Dashboard/Cameras/Player.jsx'));
const MainTables = React.lazy(() => import('./pages/Dashboard/Tables/MainTables.jsx'));
const TablePage = React.lazy(() => import('./pages/Dashboard/Tables/TablePage.jsx'));
const AllReports = React.lazy(() => import('./pages/Dashboard/Reports/AllReports.jsx'));
const EditReport = React.lazy(() => import('./pages/Dashboard/Reports/EditReport.jsx'));
const ReportsPage = React.lazy(() => import('./pages/Dashboard/Reports/ReportsPage.jsx'));
const Journal = React.lazy(() => import('./pages/Dashboard/Journal/Journal.jsx'));
const CityMap = React.lazy(() => import('./pages/Dashboard/CityMap.jsx'));
const HomePage = React.lazy(() => import('./pages/Dashboard/HomePage.jsx'));
const PersonalDash = React.lazy(() => import('./pages/PersonalDash/PersonalDash.jsx'));
const AllInstruments = React.lazy(() => import('./pages/Dashboard/Instruments/AllInstruments.jsx'));
const InstrumentsPage = React.lazy(() => import('./pages/Dashboard/Instruments/InstrumentPage.jsx'));

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);

const App = () => {

  return (
    <div>
      <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        <Route>
        <Route path="/404error" element={<NotFoundPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<HomePage />} />
          <Route element={<RequireUser allowedRoles={[1, 2, 3, 4]} />}>          
            <Route path='/' element={<Common />}>
              <Route path="personaldash" element={<PersonalDash />} />              
              <Route path="personaldash/:dash_id" element={<PersonalDash />} />      
              <Route path="/dash" element={<Dashboard />} />
              <Route path="/dash*" element={<Dashboard />} />
              <Route path="oras/:city/:city_id" element={<CityMap />} />
              <Route path="projetct/:project_name/:project_id" element={<ProjectPage />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id" element={<ProjectPage />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/:tab" element={<ProjectPage />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/:indicator_name/:indicator_id" element={<Indicator />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/:indicator_name/:indicator_id/:tab" element={<Indicator />} />
              <Route path="cases" element={<AllCases />} />
              <Route path="cases/:tab" element={<AllCases />} />
              <Route path="case/:case_id" element={<CasePage />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/case/:case_id" element={<CasePage />} />
              <Route element={<RequireUser allowedRoles={[1, 2]} />}>
                <Route path="instruments" element={<AllInstruments />} />
                <Route path="instruments/:tab" element={<AllInstruments />} />
                <Route path="instruments/id/:instrument_id" element={<InstrumentsPage />} />
                <Route path="journal/" element={<Journal />} />
                <Route path="reports/" element={<AllReports />} />
                <Route path="reports/edit/:report_id" element={<EditReport />} />
                <Route path="reports/:report_id/" element={<ReportsPage />} />   
                <Route path="settings/" element={<Settings />} />
                <Route path="settings/:tab" element={<Settings />} />
                <Route path="robots/:robot_id" element={<Robots />} />
                <Route path="robots" element={<RobotsMain />} />
                <Route path="table" element={<MainTables />} />
                <Route path="table/:table_id" element={<TablePage />} />
                <Route path="cameras/:camera_id" element={<Player />} />
                <Route path="cameras" element={<Cameras />} />
              </Route>
            </Route>
        </Route>
        </Route>
        
      </Routes>
      </Suspense>
    </div>
  );
}

export default App;